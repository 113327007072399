<template>
<div class="flex flex-col pt-6 sm:p-6">
    <Khead />
    <div class="flex justify-center">
        <div class=" mb-32 bg-white sm:w-frame  border mt-8 sm:p-8 rounded">
            <KsSignUpFirstStep :asFullPage="true"/>
        </div>
    </div>
</div>
</template>

<script>
import KsSignUpFirstStep from '@/components/AuthComponents/KsSignUpFirstStep'
import Khead from '@/components/partials/Khead'
export default {
  components: {
    KsSignUpFirstStep,
    Khead
  }
};
</script>

<style scoped>
</style>